import type { ComputedRef, Ref } from 'vue'
import type { Column, Header, Table } from '@tanstack/vue-table'
import type { DataTableProps, DataTableVariants } from '../types'
import type { VirtualItem, Virtualizer } from '@tanstack/vue-virtual'

interface UseTableStoreProps {
  table: Table<any>
  columnVirtualizer: Ref<Virtualizer<HTMLDivElement, Element>> | Ref<undefined>
  rowVirtualizer:
    | Ref<Virtualizer<HTMLDivElement, Element>>
    | Ref<Virtualizer<Window, Element>>
    | Ref<undefined>
  virtualColumns: ComputedRef<VirtualItem[] | undefined>
  virtualRows: ComputedRef<VirtualItem[] | undefined>
  variants: DataTableVariants
  hasVirtualScroller: ComputedRef<boolean>
  hasVirtualColumns: ComputedRef<boolean>
  hasVirtualRows: ComputedRef<boolean>
}

const [useProvideTableStore, useTableStore] = createInjectionState(
  (
    props: DataTableProps,
    {
      table,
      columnVirtualizer,
      rowVirtualizer,
      virtualColumns,
      virtualRows,
      variants,
      hasVirtualScroller,
      hasVirtualColumns,
      hasVirtualRows,
    }: UseTableStoreProps,
  ) => {
    // getters
    const state = computed(() => table.getState())
    const isResizingColumn = computed(
      () => state.value.columnSizingInfo.isResizingColumn,
    )

    // actions

    //These are the important styles to make sticky column pinning work!
    //Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
    //View the index.css file for more needed styles such as border-collapse: separate
    const getCommonStyles = (
      column: Column<any>,
      header?: Header<any, any>,
    ) => {
      const headerOrColumn = header ?? column
      const isPinned = column.getIsPinned()

      return {
        left:
          isPinned === 'left'
            ? `${headerOrColumn.getStart('left')}px`
            : undefined,
        right:
          isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        position: isPinned ? 'sticky' : 'relative',
        zIndex: isPinned ? 1 : 0,
      }
    }

    const displayHeader = (header: Header<any, any>) => {
      // Header group have another behavior
      if (!header.isPlaceholder && header.subHeaders.length > 0) {
        // return header.subHeaders.some(displayHeader)
        return true
      }

      return (virtualColumns.value ?? []).some(
        ({ index }) => header.index === index,
      )
    }

    return {
      table,
      columnVirtualizer,
      rowVirtualizer,
      virtualColumns,
      virtualRows,
      state,
      isResizingColumn,
      hasVirtualRows,
      hasVirtualColumns,
      hasVirtualScroller,
      getCommonStyles,
      variants,
      displayHeader,
    }
  },
)

export { useProvideTableStore, useTableStore }
